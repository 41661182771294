import { Injectable } from "@angular/core";
import { HttpClient, HttpParams, HttpBackend } from "@angular/common/http";
import { Session } from "../models/session";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root"
})
export class AuthenticationService {
  private http: HttpClient;

  constructor(handler: HttpBackend) {
    this.http = new HttpClient(handler);
  }

  getSession() {
    return JSON.parse(sessionStorage.getItem("session"));
  }

  login(username: string, password: string) {
    return this.http.post<Session>(environment.api_url + "/sessions", {
      username: username,
      password: password,
      type_id: 3
    });
  }

  logout() {
    sessionStorage.clear();
  }
}
